import React from 'react';
import Header from '../components/Header';
import Banner from '../assets/HomeBanner3.png';
import { FaDotCircle } from 'react-icons/fa';
import Footer from '../components/Footer';

const Business = () => {
  return (
    <div>
        <div className='flex flex-col relative w-screen h-[32rem]'>
            <span className="absolute w-full bg-[#180d06] h-full"><img className='w-full h-full opacity-50' src={Banner} alt='' ></img></span>
            <span className='z-20'><Header /></span>
            <span className='flex flex-col z-10 lg:px-28 px-6 gap-8 lg:mt-20 mt-28'>
                {/* <span className='flex px-6 py-1 bg-[#ffffff]/30 items-center rounded-[2rem] w-fit gap-2'>
                    <FaDotCircle className='w-4 text-[#ee803c]'/>
                    <span className='text-3xl font-semibold text-[#ffffff] tracking-wider'>Our Services</span>
                </span> */}
                <span className='flex flex-col gap-1 text-[#ffffff]'>
                    <span className='lg:text-6xl text-4xl font-semibold tracking-wide'>Business Areas</span>
                </span>
            </span>
        </div>
        <div className='flex flex-col lg:py-28 py-12 lg:gap-20 gap-12'>
            <div className='flex lg:flex-row flex-col-reverse lg:px-20 px-6 gap-6 lg:gap-0 justify-between items-center'>
                <span className='rounded-2xl bg-[#000000]/30 lg:w-[30rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru20.png")] bg-cover bg-center'></span>
                <span className='flex flex-col lg:gap-6 gap-3'>
                    <span className='flex flex-col gap-1 text-[#000000]/60'>
                        <span className='text-4xl font-bold tracking-wide'>Upstream oil & gas</span>
                    </span>
                    <span className='text-[#000000]/60 text-xl font-light lg:w-[42rem]'>Tru Energy boasts a team of exceptionally industry professionals each possessing an extensive wealth of experience in the domains of exploration and development, production, and commercial operations. Our unwaverng commitment to excellence is manifested not only in the wealth of knowledge within our team but also in our ongoing initiatives in recruiment rigorous training, and continuous professional research and development endeavors.</span>
                    <span className='flex flex-col gap-3'>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className=' lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Gas Processing Facilities</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className=' lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Asset Integrity Managment</span>
                            </span>
                        </span>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className=' lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Upstream E&P Services</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className=' lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Crude Oil Storage Tanks </span>
                            </span>
                        </span>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className=' lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Corrosion Services</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className=' lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'> Produced Water Treatment</span>
                            </span>
                        </span>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Flow Stations</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Pumps and Compressor Stations </span>
                            </span>
                        </span>
                        <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Custody transfer Metering system</span>
                            </span>
                    </span>
                </span>
            </div>
            <div className='flex lg:flex-row flex-col lg:px-20 px-6 gap-6 lg:gap-0 justify-between items-center'>
              <span className='flex flex-col lg:gap-6 gap-3'>
                <span className='flex flex-col gap-1 text-[#000000]/60'>
                    <span className='text-4xl font-bold tracking-wide'>Midstream oil & gas</span>
                </span>
                <span className='text-[#000000]/60 text-xl font-light lg:w-[42rem]'>Tru Energy has developed capability to provide efficient, cost effective solutions to meet the specific needs of our customers. Our manufacturing services in pipline, petrochemical refineries and processing plants are geared toward the highest quality, customized modular systems and products for clients throughout the midstream and downstream markets.</span>
                <span className='flex flex-col gap-3'>
                  <span className='flex lg:flex-row flex-col gap-3'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>CNG Mother-Daughter Stations</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Crude & Natural Gas Pipelines </span>
                      </span>
                  </span>
                  <span className='flex lg:flex-row flex-col gap-3'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit h-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>LPG Processing & Storage Facility</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit h-fit gap-2'>
                      <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Fire Fighting & Proctetion system</span>
                      </span>
                  </span>
                  <span className='flex lg:flex-row flex-col gap-3'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Pressure Reduction & Metering System</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Onshore Oil Processing facilities</span>
                      </span>
                  </span>
                  <span className='flex lg:flex-row flex-col gap-3'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Front End Services in Refinery & Processing Plants</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Crude Oil Storage Tanks</span>
                      </span>
                  </span>
                </span>
              </span>
              <span className='rounded-2xl bg-[#000000]/30 lg:w-[28rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru19.png")] bg-cover bg-center'></span>
            </div>
            <div className='flex lg:flex-row flex-col-reverse lg:px-20 px-6 gap-6 lg:gap-0 justify-between items-center'>
                <span className='rounded-2xl bg-[#000000]/30 lg:w-[28rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru21.png")] bg-cover bg-center'></span>
                <span className='flex flex-col lg:gap-6 gap-3'>
                    <span className='flex flex-col gap-1 text-[#000000]/60'>
                        <span className='text-4xl font-bold tracking-wide'>Operation and Maintenance Services</span>
                    </span>
                    <span className='text-[#000000]/60 text-xl font-light lg:w-[42rem]'>Our Operation NAd Maintenane Division's growing staff of skilled and experienced professionals allows us to not only complete projects but also provide operational expertise and proficient maintenance skills. Our extensive project experience has given us valuable insights into various systems, which has enhanced our learning curricula. some of our Services include:</span>
                    <span className='flex flex-col gap-3'>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Asset Support</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>O&M Traning Solutions</span>
                            </span>
                        </span>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Production support</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Custody Transfer O&M </span>
                            </span>
                        </span>
                        <span className='flex lg:flex-row flex-col gap-3'>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Production Optimisation</span>
                            </span>
                            <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                                <FaDotCircle className='w-4 text-[#ffffff]'/>
                                <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Onsite and Remote O&M Services</span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div className='flex lg:flex-row flex-col lg:px-20 px-6 gap-6 lg:gap-0 justify-between items-center'>
              <span className='flex flex-col lg:gap-6 gap-3'>
                <span className='flex flex-col gap-1 text-[#000000]/60'>
                    <span className='text-4xl font-bold tracking-wide'>Trading & Distribution</span>
                </span>
                <span className='text-[#000000]/60 text-xl font-light lg:w-[42rem]'>Tru Energy Erves as an efficient and responsible partner for commodity producers and consumers alike. By leveraging our network of longstanding relationships with international trading counterparts and using our logistics and infrastructure network. Tru Energy has traded and considerably distributed millions of tons of commodities across the value chain in Nigeria and across Africa with capacity to secure competitve and reiable freight to on the product from the loading terminals to discharge terminals. Tru Energy actively trades in:</span>
                <span className='flex flex-col gap-3'>
                  <span className='flex gap-3 flex-wrap'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Crude Oil</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Diesel </span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Kerosine</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Gasoline</span>
                      </span>
                  </span>
                  <span className='flex gap-3 flex-wrap'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit h-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Condensate</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit h-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Bitumen</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>LNG</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Gos Oil</span>
                      </span>
                  </span>
                  <span className='flex gap-3 flex-wrap'>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>LPG</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90'>Base Oil</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Naphtha</span>
                      </span>
                      <span className='flex lg:px-6 px-3 py-1 bg-[#ee803c] items-center rounded-[2rem] w-fit gap-2'>
                          <FaDotCircle className='w-4 text-[#ffffff]'/>
                          <span className='lg:text-lg text-base font-light text-[#ffffff]/90 tracking-wider'>Fuel Oil</span>
                      </span>
                  </span>
                </span>
              </span>
              <span className='rounded-2xl bg-[#000000]/30 lg:w-[30rem] w-[22rem] h-[20rem] lg:h-[30rem] bg-[url("../assets/tru31.png")] bg-cover bg-center'></span>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Business