import React from "react";
import { Routes, Route} from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Business from "./pages/Business";


function App() {
  return (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about-us' element={<About />}></Route>
        <Route path='/our services' element={<Services />}></Route>
        <Route path='/business areas' element={<Business />}></Route>
        <Route path='/contact-us' element={<Contact />}></Route>
    </Routes>
  );
}

export default App;
